import React from "react";
import "./Policy.css";
import  Background from './image/people-g94f712be1_1280.jpg';
import { Image } from 'react-bootstrap';


export default function Policy() {
  return (
 
      <body>  

      <div className="header_policy">Group Policy</div>
    
      <div className="text">Syntax R Us is dedicated to providing a harassment-free conference experience for everyone, regardless of gender, gender identity and expression, age, sexual orientation, disability, physical appearance, body size, race, ethnicity, religion (or lack thereof), or technology choices. We do not tolerate harassment of Meetup participants in any form. Sexual language and imagery is not appropriate for any conference venue, including talks, workshops, parties, Twitter and other online media. Meetup participants violating these rules may be sanctioned or expelled from Syntax R Us at the discretion of the Meetup organizers.</div>
     
      <div className="text">Harassment includes offensive verbal comments related to gender, gender identity and expression, age, sexual orientation, disability, physical appearance, body size, race, ethnicity, religion, technology choices, sexual images in public spaces, deliberate intimidation, stalking, following, harassing photography or recording, sustained disruption of talks or other events, inappropriate physical contact, and unwelcome sexual attention.
Participants asked to stop any harassing behavior are expected to comply immediately.
If a participant engages in harassing behavior, the Meetup organisers may take any action they deem appropriate, including warning the offender or expulsion from the Meetup.
If you are being harassed, notice that someone else is being harassed, or have any other concerns, please contact a member of Syntax R Us staff immediately. 
Syntax R Us staff will be happy to help participants contact venue security or local law enforcement, provide escorts, or otherwise assist those experiencing harassment to feel safe for the duration of the Meetup. We value your attendance.
We expect participants to follow these rules at all Syntax R Us events and any form of communication among participants.
</div>
<div className="title"></div>
<div className="source_link">Adapted from <a href="https://confcodeofconduct.com/">https://confcodeofconduct.com</a></div> 
<div className="source_link">Original source and credit: <a href="http://2012.jsconf.us/#/about">http://2012.jsconf.us/#/about</a> & <a href="https://geekfeminism.fandom.com/wiki/Conference_anti-harassment/Policy">The Ada Initiative</a></div> 
<div className="source_link">This work is licensed under a Creative Commons Attribution 3.0 Unported License <a href="https://creativecommons.org/licenses/by/3.0/deed.en_US">https://creativecommons.org/licenses/by/3.0/deed.en_US</a></div> 

      <Image
       src={Background}
       fluid
       style={{ 
        position: "absolute",
            width: "100%",
            left: "50%",
            top: "50%",
            height: "100%",
            objectFit: "cover",
            transform: "translate(-50%,-50%)", 
            zIndex: "-1"
      
      }}
       />
    
      </body>
    )
  }

