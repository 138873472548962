import React from "react";
import "./About.css";
import YoutubeEmbed from "./YoutubeEmbed.js";
import Background from './image/cup-of-coffee-g8e2e8ad3c_1280.jpg';
import { Image } from 'react-bootstrap';


export default function About() {
  return (
    <body>
      <div className="header_about">About</div>  
      <YoutubeEmbed embedId="-pG5L4xchtQ" />
      <div className="description_text">Syntax R Us is a software development club! We learn, help, and study together as a group. You should join if you love programming in any language and want to engage with other like minded inviduals around the Severn, MD area. We will have study groups, social events, and speakers.</div>
  
    <Image
     src={Background}
     fluid
     style={{ 
      position: "absolute",
            width: "100%",
            left: "50%",
            top: "50%",
            height: "100%",
            objectFit: "cover",
            transform: "translate(-50%,-50%)", 
            zIndex: "-1"  
    }}
     />
    </body>
  );
}


