import React from "react";
import "./Founder.css";
import  Background from './image/office-g95931ccec_1280.jpg';
import { Container, Row, Col, Image } from 'react-bootstrap';
import  Sujoy from './image/Sujoy.jpg';


export default function Founder() {
  return (
 
 <body>
 <div className="header_founder">Founder</div>
 <div className="content_founder"> 
 <Container >
   <Row>
   <Col sm={5} md={5}>
   <div className="sujoy_picture"><Image src={Sujoy}
         roundedCircle
        style={{ 
              width: "45%",
              left: "5%",
              top: "25%",
              height: "50%"
        }}
            /></div>
   </Col>
    <Col>
    My name is Sujoy, and I am the founder of Syntax R Us. I created Syntax R Us because I know how hard it can be to study programming and how easy it is to get lost in the process. So, I decided to create this group so we can network, help, and solve problems together. This way it’s a team effort in helping programmers out which softens the pain and build confidence in this ever-changing IT world.  By making this friendly environment with programmers with all types of background in different programming languages keeps us unique, diverse, and great resource community. 
   </Col>
  </Row>
</Container>
      <Image
       src={Background}
       fluid
       style={{ 
        position: "absolute",
            width: "100%",
            left: "50%",
            top: "50%",
            height: "100%",
            objectFit: "cover",
            transform: "translate(-50%,-50%)", 
            zIndex: "-1"      
      }}
       />
      </div>
      
      </body>
    )
  }