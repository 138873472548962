import React, { Component } from 'react'
import Backgroud from './video/Chain - 25380.mp4';
import SRUImage from './image/sru.png';
import {  Image } from 'react-bootstrap';
import "./Home.css";

export default class Home extends Component {
  render() {
    return (
      
        <div>
        <video autoPlay loop muted
        style={{
          position: "absolute",
          width: "100%",
          left: "50%",
          top: "50%",
          height: "100%",
          objectFit: "cover",
          transform: "translate(-50%,-50%)", 
          zIndex: "-1"
        }}
        >
          <source src= {Backgroud} type="video/mp4" />
        </video>

     <Image
     src={SRUImage}
     fluid
     style={{ 
      paddingTop: "12%",
      paddingBottom: "7%",
      maxWidth: '50%',
      position: "center",

     
    
    }}
     />
  
</div>

    )
  }
}






