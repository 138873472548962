import React from "react";
import "./Contact.css";
import  Background from './image/tablet-g24059b901_1280.jpg';
import { Image } from 'react-bootstrap';

export default function Contact() {
  return (
 
      <body>
      <div className="header_contact">Contact</div>
      <div className="text_contact">You can contact Syntax R Us by email at syntaxrus@gmail.com and follow us on Twitter at <a href="https://twitter.com/SyntaxRUs">https://twitter.com/SyntaxRUs</a></div>  
      <div className="text_contact">We also have a Slack workspace that you can request to join by email.</div>

      <Image
       src={Background}
       fluid
       style={{ 
        position: "absolute",
            width: "100%",
            left: "50%",
            top: "50%",
            height: "100%",
            objectFit: "cover",
            transform: "translate(-50%,-50%)", 
            zIndex: "-1"
      }}
       />
 
      </body>
      
    )
  }