import React, { Component } from 'react';
import {Navbar, Nav, Container } from 'react-bootstrap';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from "react-router-dom";
import About from './About';
import Contact from './Contact';
import Home from './Home';
import Policy from './Policy';
import Founder from './Founder';



export default class NavbarComp extends Component {
  render() {
    return (
      <Router>
      <div>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
      <Navbar.Brand as={Link} to={"/"}> Syntax R Us</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link as={Link} to={"/about"}>About</Nav.Link>
          <Nav.Link as={Link} to={"/policy"}>Group Policy</Nav.Link>
          <Nav.Link as={Link} to={"/founder"}>Founder</Nav.Link>
          
        </Nav>
        <Nav>
          <Nav.Link href="https://www.meetup.com/syntax-r-us/">Join Group</Nav.Link>
          <Nav.Link as={Link} to={"/contact"}>Contact Us</Nav.Link>
        </Nav>
      </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>

    <div>  
    <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={ <Contact />} />
          <Route path="/policy" element={ <Policy />} />
          <Route path="/founder" element={ <Founder />} />
          <Route path="/"  element={ <Home />} />
          
    </Routes>
    </div>
    </Router>
    );
  }
}
